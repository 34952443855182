<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-6">
          <strong>Payment Methods</strong>
        </div>
        <div class="col-6 text-right">
          <SubscriptionMethodAdd
            :visible="visible"
            @close="visible = false"
            @success="listSources"
          >
            <a-button type="primary" ghost size="small" @click="visible = true">
              <i class="fal fa-plus mr-2"></i> Add Payment Method
            </a-button>
          </SubscriptionMethodAdd>
        </div>
      </div>
    </div>
    <BaseSpinner :loading="loading">
      <div v-for="(source, i) in sources" :key="source.id" class="card-body" :class="{ 'border-bottom': i < sources.length - 1  }">
        <SubscriptionMethodItem
          :defaultSource="defaultSource"
          :source="source"
          @change="listSources"
        />
      </div>
      <div v-if="!sources.length && !loading" class="card-body text-muted text-center">
        <i class="fad fa-empty-set mr-1"></i> No payment method on file.
      </div>
    </BaseSpinner>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'
import SubscriptionMethodAdd from '@/components/Subscription/SubscriptionMethodAdd'
import SubscriptionMethodItem from '@/components/Subscription/SubscriptionMethodItem'

export default {
  components: {
    BaseSpinner,
    SubscriptionMethodAdd,
    SubscriptionMethodItem,
  },

  data() {
    return {
      loading: true,
      visible: false,
      defaultSource: null,
      sources: [],
    }
  },

  mounted() {
    this.listSources()
  },

  methods: {
    async listSources() {
      try {
        this.loading = true
        this.visible = false

        var { data } = await this.$httpd.get(`/methods`)

        this.sources = data.sources
        this.defaultSource = data.defaultSource
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
