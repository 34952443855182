<template>
  <a-form @submit.prevent.native="onChange">
    <div class="card">
      <div class="card-header">
        <strong>Activate Features</strong>
      </div>
      <div class="card-body">
        <p>Small businesses save hours of dealing with Excel sheets and enterprises save an army of accountants. Use Vatstack’s wonderful features to automate VAT handling for you.</p>
        <div class="row">
          <div v-for="plan in activePlans" :key="plan.id" class="col-12 col-lg-4 mb-3">
            <div
              class="bg-white rounded border p-3 h-100"
              :class="{
                'border-primary': plan.id === values.plan,
                'border-primary-inset': plan.id === values.plan,
              }"
              style="cursor: pointer;"
              @click="values.plan = plan.id"
            >
              <div class="d-flex justify-content-between mb-3">
                <h2 class="h5">{{ plan.name }}</h2>
                <div class="float-right text-right">
                  <div class="small">Starts at</div>
                  <span class="lead text-primary"><strong>${{ plan.price }}</strong><small class="text-muted">/mo*</small></span>
                </div>
              </div>
              <small>
                <ul class="fa-ul" style="margin-left: 1.25rem;">
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> <strong>Unlimited</strong> quotes
                  </li>
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> <strong>{{ plan.supplyHits }}</strong> supplies per month
                    <div class="text-muted">${{ plan.supplyCost }} per additional</div>
                  </li>
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> <strong>{{ plan.validationHits }}</strong> validations per month
                    <div class="text-muted">${{ plan.validationCost }} per additional</div>
                  </li>
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> Automated VAT reports
                  </li>
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> Integrations
                  </li>
                  <li class="mb-1">
                    <span class="fa-li"><i class="far fa-check text-success"></i></span> Webhook events
                  </li>
                </ul>
              </small>
            </div>
          </div>
        </div>
        <small>
          <p>* All prices are in USD, exclusive of VAT. To benefit from the reverse-charge mechanism, please save your VAT ID in the billing address. Visit the <a href="https://vatstack.com/pricing" target="_blank">pricing page</a> to review all product features for each plan.</p>
        </small>
      </div>
      <div class="card-footer">
        <a-button type="primary" html-type="submit" :disabled="!values.plan">
          Activate
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      values: {
        plan: null,
      },
    }
  },

  computed: {
    activePlans() {
      return this.$store.state.plans.filter(item => item.active === true)
    },
  },

  methods: {
    onChange() {
      this.$confirm({
        title: 'Confirm Subscription',
        content: 'Great choice! All features will be activated with the subscription.',
        okText: 'Confirm',
        onOk: async () => {
          await this.onConfirm()
        },
        onCancel() {},
      })
    },
    async onConfirm() {
      try {
        var { data } = await this.$httpd.post(`/subscriptions`, this.values)

        this.$store.commit('user', data)
        this.$message.success('Subscription has been created.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }
    },
  },
}
</script>
