<template>
  <div>
    <SubscriptionMigrate v-if="$store.getters.isOld" class="mb-3" />
    <SubscriptionUpgrade v-else-if="!$store.state.user.stripe.product" class="mb-3" />
    <SubscriptionPlan v-else class="mb-3" />
    <SubscriptionMethod class="mb-3" />
  </div>
</template>

<script>
import SubscriptionMethod from '@/components/Subscription/SubscriptionMethod'
import SubscriptionMigrate from '@/components/Subscription/SubscriptionMigrate'
import SubscriptionPlan from '@/components/Subscription/SubscriptionPlan'
import SubscriptionUpgrade from '@/components/Subscription/SubscriptionUpgrade'

export default {
  components: {
    SubscriptionMethod,
    SubscriptionMigrate,
    SubscriptionPlan,
    SubscriptionUpgrade,
  },
}
</script>
