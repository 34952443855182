<template>
  <div>
    <div class="row align-items-center">
      <div class="col-3">
        <div v-if="source.type === 'card'">
          <img width="40" height="30" style="vertical-align: middle" :src="getCardIcon(source.card.brand)" :alt="source.card.brand">
        </div>
        <div v-if="source.type === 'sepa_debit'">
          SEPA Direct Debit
        </div>
      </div>
      <div class="col-3">
        <label class="label mb-0">Last 4</label>
        <div v-if="source.type === 'card'">
          •••• {{ source.card.last4 }} <span class="badge badge-primary ml-2" v-if="source.id === defaultSource">Primary</span>
        </div>
        <div v-if="source.type === 'sepa_debit'">
          •••• {{ source.sepa_debit.last4 }} <span class="badge badge-primary ml-2" v-if="source.id === defaultSource">Primary</span>
        </div>
      </div>
      <div class="col-3">
        <div v-if="source.type === 'card'">
          <label class="label mb-0">Expiry</label>
          <div>{{ source.card.exp_month}} / {{ source.card.exp_year }}</div>
        </div>
        <div v-if="source.type === 'sepa_debit'">
          <label class="label mb-0">Bank Code</label>
          <div>{{ source.sepa_debit.bank_code }}</div>
        </div>
      </div>
      <div class="col-3 text-right">
        <a-dropdown :trigger="['click']">
          <a-button size="small">
            <i class="fal fa-ellipsis-h"></i>
          </a-button>
          <a-menu slot="overlay" @click="onClick">
            <a-menu-item v-if="source.id !== defaultSource" key="primary">
              Make Primary
            </a-menu-item>
            <a-menu-divider v-if="source.id !== defaultSource" />
            <a-menu-item key="delete">
              <span class="text-danger"><i class="fal fa-fw fa-minus-circle"></i> Remove</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: Object,
    defaultSource: String,
  },

  data() {
    return {
      visible: false,
    }
  },

  methods: {
    async onClick({ key }) {
      switch (key) {
        case 'primary':
          try {
            var { data } = await this.$httpd.put(`/methods/${ this.source.id }`)
          } catch (err) {
            if (err.response) {
              this.$message.error(err.response.data.message)
            } else {
              this.$message.error(err.message)
            }

            return false
          }

          this.$message.success('Payment method has been updated.')
          this.$emit('change')
        break

        case 'delete':
          if (this.source.id === this.defaultSource) {
            this.$error({
              title: 'Primary Payment Method',
              content: 'The primary payment method cannot be removed. Please select or add a new payment method first.',
              onCancel() {},
            })
          } else {
            this.$confirm({
              title: 'Remove Payment Method',
              content: 'This payment method will be removed from your account. The action cannot be undone.',
              okText: 'Remove',
              okType: 'danger',
              onOk: async () => {
                await this.onDelete()
              },
              onCancel() {},
            })
          }
        break
      }
    },
    async onDelete() {
      try {
        var { data } = await this.$httpd.delete(`/methods/${ this.source.id }`)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      this.$message.success('Payment method has been deleted.')
      this.$emit('change')
    },
    getCardIcon(brand) {
      var brands = ['alipay', 'amex', 'diners', 'discover', 'elo', 'hipercard', 'jcb', 'maestro', 'mastercard', 'paypal', 'unionpay', 'verve', 'visa']

      if (brands.indexOf(brand) >= 0) {
        return require(`@/assets/images/cards/${ brand }.svg`)
      } else {
        return require(`@/assets/images/cards/default.svg`)
      }
    },
  },
}
</script>
